import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../context/authContext';
import Config from '../../../Config';
import DataTable from '../../../components/DataTable';
import Column from '../../../components/Column';

const LoginHistory = () => {
    const [loginHistory, setLoginHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { userCredentials } = useAuth();
    useEffect(() => {
        const fetchLoginHistory = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${Config.settings.baseURL}/v1/loginHistory/${userCredentials.userId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': btoa(JSON.stringify(userCredentials))
                    },
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch login history');
                }
                const data = await response.json();
                setLoginHistory(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchLoginHistory();
    }, [userCredentials, userCredentials.userId]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
            <DataTable value={loginHistory.data} tableStyle={{ minWidth: '50rem' }}>
                <Column field="user_id" header="Id"></Column>
                <Column field="date" header="Data"></Column>
                <Column field="ip" header="IP"></Column>
                <Column field="browser" header="Informacje"></Column>
            </DataTable>
        </div>
    );
};

export default LoginHistory;
