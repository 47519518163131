import React, { useState } from 'react';
const Input = ({ label, name, value, onChange, disabled }) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

    return (
        <div className="relative flex flex-col">
            <label
                htmlFor={name}
                className={`absolute left-2 text-gray-700 font-thin text-sm transition-all duration-200 ${(isFocused || value.length > 0) ? 'top-0 text-xs' : 'top-1/2 transform -translate-y-1/2'
                    }`}
            >
                {label}
            </label>
            <input
                type="text"
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                disabled={disabled}
                style={{
                    borderBottom: value.length > 0 ? `1px solid #1e293b` : '',
                    background: value.length > 0 ? `#f8fafc` : '',
                }}
                className="border-b border-gray-300 p-2 pt-6" // Add pt-6 to give space for the label
            />
        </div>
    );
};

export default Input;
