export const filterFieldsData = {
    sad: '',
    numerKsiegi: '',
    wojewodztwo: '',
    powiat: '',
    gmina: '',
    miejscowosc: '',
    ulica: '',
    identyfikatorDzialki: '',
    numerDzialki: '',
    obrebEwidencyjny: '',
    sposobKorzystania: '',
    deepSearch: '',
    kwBook: '',

    numerBudynku: '',
    numerLokalu: '',
    kondygnacja: '',
    powierzchniaLokaluOd: '',
    powierzchniaLokaluDo: '',
    imieWlasciciela: '',
    nazwiskoWlasciciela: '',
    imieOjca: '',
    imieMatki: '',
    peselWlasciciela: '',
    komornik: '',
    darowizny: '',
    warteSprawdzenia: '',
    umowyDeweloperskie: '',
    umowyWarunkowePrzedwstepne: '',
}