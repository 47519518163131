import { Outlet } from 'react-router-dom';
import LeftSidebar from '../../components/LeftSidebar.jsx';
import Breadcrumbs from '../../components/BreadCrumbs.jsx';

const Dashboard = () => {

  const menuData = [
    {
      "category": "Panel",
      "links": [
        {
          "href": "/dashboard/home",
          "label": "Start",
          "icon": `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"></path>
          </svg>`
        }
      ]
    },
    {
      "category": "Księgi",
      "links": [
        {
          "href": "/dashboard/ksiegi",
          "label": "Lista ksiąg",
          "icon": `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z"></path>
          </svg>`
        }
      ]
    },
    {
      "category": "Użytkownik",
      "links": [
        {
          "href": "/dashboard/user",
          "label": "Profil",
          "icon": `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"></path>
          </svg>`
        },
        {
          "href": "/dashboard/logout",
          "label": "Wyloguj",
          "icon": `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"></path>
              </svg>`
        },
      ]
    }
  ];

  return (
    <div className="flex flex-nowrap h-screen overflow-hidden">
      {/* Sidebar */}
      <div className='w-64 h-full overflow-hidden relative'>
        <LeftSidebar props={menuData} />
      </div>

      {/* Main */}
      <main className='bg-slate-50 relative flex flex-col flex-1'>
        {/* Header */}
        <div className='h-24 flex items-center flex-shrink-0 px-10 border-b bg-white'>
          <h1 className='m-0 font-thin text-md'>Dashboard</h1>
        </div>

        <div className="h-12 flex items-center flex-shrink-0 px-10">
          <Breadcrumbs />
        </div>

        {/* Content (Outlet) */}
        <div className='max-w-[calc(100vw_-_16rem)] px-10 flex-1 overflow-y-auto pt-10'>
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
