import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../context/authContext';
import TileContainer from '../../../components/TileContainer';
import AccountManagement from './AccountManagement';
import UserManagement from './UserManagement';
import LoginHistory from './LoginHistory';

const UserProfile = () => {
    const { user } = useAuth();
    const [view, setView] = useState('account');
    const [tileHeading, setTileHeading] = useState({ header: "Konto", subHeader: "Zarządzaj kontem" });

    useEffect(() => {
        updateTileHeading(view);
    }, [view]);

    const updateTileHeading = (selectedView) => {
        switch (selectedView) {
            case 'account':
                setTileHeading({ header: "Konto", subHeader: "Zarządzaj kontem" });
                break;

            case 'loginhistory':
                setTileHeading({ header: "Historia logowania", subHeader: "Obecny użytkownik" });
                break;
            default:
                setTileHeading({ header: "", subHeader: "" });
        }
    };

    const renderView = () => {
        switch (view) {
            case 'account':
                return <AccountManagement user={user} />;
            case 'users':
                return <UserManagement />;
            case 'loginhistory':
                return <LoginHistory />;
            default:
                return null;
        }
    };

    const handleViewChange = (newView) => {
        setView(newView);
    };

    return (
        <TileContainer header={tileHeading.header} subHeader={tileHeading.subHeader}>
            <div className="shrink-0 bg-border h-[1px] w-full"></div>
            <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
                <SideNavigation view={view} onViewChange={handleViewChange} />
                {renderView()}
            </div>
        </TileContainer>
    );
};

const SideNavigation = ({ view, onViewChange }) => {
    const menuItems = [
        { key: 'account', label: 'Konto' },
        { key: 'loginhistory', label: 'Historia logowania' },
    ];

    return (
        <nav className="min-w-36">
            <ul>
                {menuItems.map((item) => (
                    <li
                        key={item.key}
                        onClick={() => onViewChange(item.key)}
                        className={`cursor-pointer pb-2 hover:underline ${view === item.key ? 'underline' : ''}`}
                    >
                        {item.label}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default UserProfile;
