import React from 'react'
import Input from '../../../components/Input'
import { useAuth } from '../../../context/authContext'

const AccountManagement = () => {
  const { user } = useAuth()
  return (
    <div className="w-full grid grid-cols-1 gap-4">
      <div className="flex flex-col">
        <Input label={'Login'} value={user.username} disabled={true} />
      </div>
      <div className="flex flex-col">
        <Input label={'Hasło'} value={'••••••••'} disabled={true} />
      </div>
      <div className="flex flex-col">
        <button className="bg-slate-100 border border-slate-200 p-2 ml-auto" onClick={() => {alert('Zapisano')}}>Zapisz</button>
      </div>
    </div>
  )
}

export default AccountManagement