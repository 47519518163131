import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Config from '../../Config';

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const handleLogout = async () => {
            try {
                // Send a request to the backend to log out
                const response = await fetch(`${Config.settings.baseURL}/v1/logout`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        // Include authorization header if needed
                    }
                });

                if (response.ok) {
                    localStorage.clear();
                    // Redirect to the login page or home
                    navigate('/login');
                } else {
                    console.error('Logout failed');
                }
            } catch (error) {
                console.error('An error occurred during logout:', error);
            }

        };
        handleLogout();
    }, [navigate]);

    return <div>Logging out...</div>;
};

export default Logout;
