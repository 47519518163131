import React from 'react'

const TileContainer = ({ children, header, subHeader }) => {
    return (
        <div className="space-y-6 p-10 mb-10 md:block bg-white drop-shadow-sm">
            <div className="space-y-0.5">
                {header && <h2 className="text-xl font-bold tracking-tight">{header || ""}</h2>}
                <p className="text-muted-foreground">
                    {subHeader || ""}
                </p>
            </div>
            {children}
        </div>
    )
}

export default TileContainer