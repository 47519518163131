import { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Config from '../Config';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState("")
  const [token, setToken] = useState("")
  const navigate = useNavigate();

  const login = async (username, password, singleToken) => {
    
    try {
      const response = await fetch(`${Config.settings.baseURL}/v1/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': singleToken
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();
      if (data.status === "success") {
        setUser({ username });
        setToken(data.data.userToken)
        setUserId(data.data.userId)
        navigate('/dashboard/home');
      } else {
        alert('Niepoprawne dane logowania');
      }
    } catch (error) {
      console.error('Błąd podczas logowania:', error);
    }
  };

  const userCredentials = {
    userId,
    userToken: token
  }

  const logout = () => {
    setUser(null);
    navigate('/login');
  };

  const value = { token, user, userId, userCredentials, login, logout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
