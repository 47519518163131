import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/authContext.jsx';
import Login from './features/auth/Login.jsx';
import Dashboard from './features/dashboard/Dashboard.jsx';
import ProtectedRoute from './routes/ProtectedRoute.jsx';
import UserProfile from './features/dashboard/user/UserProfile.jsx';
import Kw from './features/dashboard/books/Kw.jsx';
import KwDetails from './features/dashboard/books/KwDetails.jsx';
import Logout from './features/auth/Logout.jsx';
import DashboardHome from './features/dashboard/dashboardHome/DashboardHome.jsx';

const RedirectToDashboardOrLogin = () => {
  const { user } = useAuth();
  return user ? <Navigate to="/dashboard/home" /> : <Navigate to="/login" />;
};


const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Homepage redirect "/" */}
          <Route path="/" element={<RedirectToDashboardOrLogin />} />

          <Route path="/login" element={<Login />} />

          {/* Protected route "/dashboard" */}
          <Route
            path="/dashboard/*"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          >
            {/* Dashboard subpages */}
            <Route path="home" element={<DashboardHome />} />
            <Route path="ksiegi" element={<Kw />} />
            <Route path="ksiegi/:id" element={<KwDetails />} />
            <Route path="user" element={<UserProfile />} />
            <Route path="logout" element={<Logout />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;