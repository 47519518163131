import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../../../styles/kwStyles.css';
import Config from '../../../Config.js';
import { useAuth } from '../../../context/authContext.jsx';
import DataTable from '../../../components/DataTable.jsx';
import Column from '../../../components/Column.jsx';
import MaskLoader from '../../../components/MaskLoader.jsx';
import TileContainer from '../../../components/TileContainer.jsx';
import AlertBox from '../../../components/AlertBox.jsx';

const KwDetails = () => { 
    const { userCredentials } = useAuth();
    const { id } = useParams();

    const [data, setData] = useState(null);
    const [owners, setOwners] = useState(null);
    const [kw, setKw] = useState(null);
    const [showAlert, setShowAlert] = useState({
        visible: false,
        message: "",
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [responseContent, responseOwners, responseKw] = await Promise.all([
                    fetch(`${Config.settings.baseURL}/v1/content/${id}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': btoa(JSON.stringify(userCredentials))
                        }
                    }),
                    fetch(`${Config.settings.baseURL}/v1/owners/${id}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': btoa(JSON.stringify(userCredentials))
                        }
                    }),
                    fetch(`${Config.settings.baseURL}/v1/kw/${id}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': btoa(JSON.stringify(userCredentials))
                        }
                    })
                ]);

                if (!responseContent.ok) throw new Error('Content data response was not ok');
                if (!responseOwners.ok) throw new Error('Owners data response was not ok');
                if (!responseKw.ok) throw new Error('Kw data response was not ok');

                const resultContent = await responseContent.json();
                const resultOwners = await responseOwners.json();
                const resultKw = await responseKw.json();

                if (resultContent.status === 'success' && resultContent.data.length > 0) setData(resultContent.data);
                if (resultOwners.status === 'success' && resultOwners.data.length > 0) setOwners(resultOwners.data);
                if (resultKw.status === 'success' && resultKw.data.length > 0) setKw(resultKw.data);

            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        if (id) { // Ensure that the effect only runs if `id` is available
            fetchData();
        }
    }, [id]); // Only depend on `id`


    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setShowAlert({
                    visible: true,
                    message: "Skopiowano do schowka"
                });
            })
            .catch((err) => {
                console.error("Failed to copy: ", err);
            });
    };

    useEffect(() => {
        if (showAlert.visible) {
            const timer = setTimeout(() => {
                setShowAlert({
                    visible: false,
                    message: ''
                });
            }, 1500);

            return () => clearTimeout(timer);
        }
    }, [showAlert.visible]);


    if (loading) return <div><MaskLoader /></div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <>
            {showAlert.visible && <AlertBox message={showAlert.message} />}
            {kw && <TileContainer>
                {kw.map((item, index) => (
                    <span
                        key={index}
                        className="copyable-content hover:underline cursor-pointer"
                        onClick={() => copyToClipboard(`${item.court}/${item.kwNumber.split("_").join("/")}`)}
                    >
                        {`${item.court}/${item.kwNumber.split("_").join("/")}`}
                    </span>
                ))}
            </TileContainer>}
            {owners && <TileContainer>
                <DataTable value={owners} tableStyle={{}}>
                    <Column field="imie" header="Imie" />
                    <Column field="drugieImie" header="Drugie imie" />
                    <Column field="nazwisko" header="Nazwisko" />
                    <Column field="drugieNazwisko" header="Drugie nazwisko" />
                    <Column field="pesel" header="PESEL" />
                    <Column field="imieOjca" header="Imie ojca" />
                    <Column field="imieMatki" header="Imie matki" />
                </DataTable>
            </TileContainer>}
            {data && <div dangerouslySetInnerHTML={{
                __html: `
                <div>${data[0].content1 || ""}</div>
                <div>${data[0].content2 || ""}</div>
                <div>${data[0].content3 || ""}</div>
                <div>${data[0].content4 || ""}</div>
                <div>${data[0].content5 || ""}</div>
            `}} />}
        </>
    );
}

export default KwDetails;
