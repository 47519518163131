import React from 'react'
import { Link } from 'react-router-dom'

const LeftSidebar = ({ props }) => {

    return (
        <aside className="flex flex-col w-64 h-full px-5 py-8 overflow-y-auto bg-white border-r rtl:border-r-0 rtl:border-l ">
            <div className="flex flex-col justify-between flex-1">
                <nav className="-mx-3 space-y-6 ">
                    {props.map((val,key) => {
                        return (
                            <div className="space-y-3" key={key}>
                                <label className="px-3 text-xs text-gray-500 uppercase ">{val.category}</label>
                                {val.links.map((link,key) => {
                                    return (<Link key={key} className="flex items-center px-3 py-2 text-gray-600 transition-colors duration-300 transform rounded-lg hover:bg-slate-100 hover:text-slate-700" to={link.href}>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: link.icon }}
                                        />
                                        <span className="mx-2 text-sm font-medium">{link.label}</span>
                                    </Link>)
                                })}
                            </div>
                        )
                    })}
                </nav>
            </div>
        </aside>
    )
}

export default LeftSidebar