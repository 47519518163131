import React from 'react';

const DataTable = ({ value, tableStyle, children }) => {
    const columns = React.Children.toArray(children);

    return (
        <div className="flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block align-middle">
                    <div className="overflow-hidden"></div>
                    <table className="min-w-full bg-white border border-slate-200">
                        <thead>
                            <tr className='bg-slate-100 text-slate-600 uppercase text-sm leading-normal'>
                                {columns.map((column) => (
                                    <th className='px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase hover:bg-slate-200 cursor-pointer' key={column.props.field}>{column.props.header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 ">
                            {value.map((item, rowIndex) => (
                                <tr className="border-b border-slate-200 hover:bg-slate-50 cursor-pointer" key={rowIndex}>
                                    {columns.map((column) => (
                                        <td className="px-6 py-4 text-sm text-gray-800 " key={column.props.field}>{item[column.props.field]}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};


export default DataTable
