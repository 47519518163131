export const displayedColumnsData = {
    kwNumber: "Numer Księgi",
    court: "Sąd",
    numerDzialki: "Numer Działki",
    pesel: "pesel",
    gmina: "Gmina",
    miejscowosc: "Miejscowość",
    ulica: "Ulica",
    numerBudynku: "Nr Budynku",
    numerLokalu: "Nr Lokalu",
    obrebEwidencyjny: "Obręb ewidencyjny",
    sposobKorzystania: "Sposób Korzystania",
    imie: "Imie",
    nazwisko: "Nazwisko",
    date: "Data",
}