import React from 'react'
import loaderIcon from '../assets/images/desperados.png'
const MaskLoader = () => {
    return (
        <div role="status" className="w-full p-4 space-y-4  divide-y divide-slate-200 rounded  animate-pulse md:p-6 relative">
            <div className="flex items-center justify-between">
                <div>
                    <div className="h-2.5 bg-slate-300 rounded-full w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-slate-200 rounded-full "></div>
                </div>
                <div className="h-2.5 bg-slate-300 rounded-full  w-12"></div>
            </div>
            <div className="flex items-center justify-between pt-4">
                <div>
                    <div className="h-2.5 bg-slate-300 rounded-full w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-slate-200 rounded-full "></div>
                </div>
                <div className="h-2.5 bg-slate-300 rounded-full  w-12"></div>
            </div>
            <div className="flex items-center justify-between pt-4">
                <div>
                    <div className="h-2.5 bg-slate-300 rounded-full w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-slate-200 rounded-full "></div>
                </div>
                <div className="h-2.5 bg-slate-300 rounded-full  w-12"></div>
            </div>
            <div className="flex items-center justify-between pt-4">
                <div>
                    <div className="h-2.5 bg-slate-300 rounded-full w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-slate-200 rounded-full "></div>
                </div>
                <div className="h-2.5 bg-slate-300 rounded-full  w-12"></div>
            </div>
            <div className="flex items-center justify-between pt-4">
                <div>
                    <div className="h-2.5 bg-slate-300 rounded-full w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-slate-200 rounded-full "></div>
                </div>
                <div className="h-2.5 bg-slate-300 rounded-full  w-12"></div>
            </div>
            <span className="sr-only">Loading...</span>
            <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                <img className="h-24 animate-spin" src={loaderIcon} alt="spinner"/>
            </div>
        </div>

    )
}

export default MaskLoader