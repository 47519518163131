import React from 'react';

const Checkbox = ({ label, name, checked, onChange }) => {
    const checkboxId = `checkbox-${name}`;
    
    return (
        <div className="flex items-center">
            <input
                id={checkboxId}
                type="checkbox"
                name={name}
                checked={checked}
                onChange={onChange}
                className="h-5 w-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
            />
            <label htmlFor={checkboxId} className="ml-2 text-gray-700 font-thin text-sm">
                {label}
            </label>
        </div>
    );
};

export default Checkbox;
