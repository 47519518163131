import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Breadcrumbs = () => {
    const location = useLocation();

    const pathnames = location.pathname.split('/').filter((x) => x);

    return (
        <nav aria-label="breadcrumbs">
            <ol className="flex items-center whitespace-nowrap">
                {/* Tworzenie linków dla każdej części ścieżki */}
                {pathnames.map((value, index) => {
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                    const isLast = index === pathnames.length - 1;

                    return (
                        <li key={to} className="inline-flex items-center">
                            {!isLast ? (
                                <>
                                    <Link
                                        className="flex items-center text-sm text-gray-500 hover:text-slate-600 focus:outline-none focus:text-slate-600"
                                        to={to}
                                    >
                                        {value.charAt(0).toUpperCase() + value.slice(1)}
                                    </Link>
                                    <svg
                                        className="shrink-0 mx-2 size-4 text-gray-400"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path d="M9 18l6-6-6-6" />
                                    </svg>
                                </>
                            ) : (
                                <span className="text-sm font-semibold text-gray-800 truncate">
                                    {value.charAt(0).toUpperCase() + value.slice(1)}
                                </span>
                            )}
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
};

export default Breadcrumbs;
