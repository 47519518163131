import React, { useEffect, useState } from 'react';
import TileContainer from '../../../components/TileContainer';
import Input from '../../../components/Input';
import Checkbox from '../../../components/Checkbox';
import { useNavigate } from 'react-router-dom';
import Alert from '../../../components/Alert';
import Config from '../../../Config.js';
import MaskLoader from '../../../components/MaskLoader.jsx'
import { useAuth } from '../../../context/authContext.jsx';
import SelectInput from '../../../components/SelectInput.jsx';
import { filterFieldsData } from './utils/filterFields.jsx';
import { displayedColumnsData } from './utils/displayedColumns.jsx';

const Kw = () => {
    const { userCredentials } = useAuth();

    const [filter, setFilter] = useState(filterFieldsData);
    const [displayedColumns, setDisplayedColumns] = useState(displayedColumnsData);

    const initialSortState = JSON.parse(localStorage.getItem('sortState')) || {
        column: '',
        direction: 'asc',
    };
    const [sortState, setSortState] = useState(initialSortState);

    useEffect(() => {
        handleSearch(filter);
        localStorage.setItem('sortState', JSON.stringify(sortState));
    }, [sortState]);

    // This handles the column header click to toggle sort direction
    const handleHeaderClick = (column) => {
        const newDirection = sortState.column === column && sortState.direction === 'asc' ? 'desc' : 'asc';
        setSortState({
            column: column,
            direction: newDirection,
        });
    };

    const initialPagination = JSON.parse(localStorage.getItem('pagination')) || {
        limit: 25,
        offset: 0,
        page: 1,
    };
    const [pagination, setPagination] = useState(initialPagination);

    const [pageError, setPageError] = useState({ isVisible: false, type: "", message: "" });
    const [result, setResult] = useState(null);
    const [courts, setCourts] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const navigate = useNavigate();

    const booksList = ["Dział I-O", "Dział I-Sp", "Dział II", "Dział III", "Dział IV"];

    useEffect(() => {
        const storedFilters = JSON.parse(localStorage.getItem('filters'));
        if (storedFilters) {
            setFilter(storedFilters);
            handleSearch(storedFilters); // Automatycznie wykonaj zapytanie z zapisanymi filtrami
        }
        localStorage.setItem('pagination', JSON.stringify(pagination));
    }, [pagination]);

    useEffect(() => {
        localStorage.setItem('filters', JSON.stringify(filter));
    }, [filter]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilter((prevFilter) => ({
            ...prevFilter,
            [name]: value,
        }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFilter((prevFilter) => ({
            ...prevFilter,
            [name]: checked ? true : "",
        }));
    };

    // This is the function that fetches data from the API based on current filters and sort state
    const handleSearch = async (currentFilters) => {
        try {
            setLoading(true);
            const queryParams = {
                ...currentFilters,
                sortBy: sortState.column,
                sortDirection: sortState.direction,
            };

            const queryString = new URLSearchParams(queryParams).toString();

            const queryResponse = await fetch(`${Config.settings.baseURL}/v1/query?${queryString}&limit=${pagination.limit}&offset=${pagination.offset}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': btoa(JSON.stringify(userCredentials)),
                },
            });

            const courtsResponse = await fetch(`${Config.settings.baseURL}/v1/getCourts`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': btoa(JSON.stringify(userCredentials)),
                },
            });

            if (!queryResponse.ok) {
                setPageError({
                    isVisible: true,
                    message: "Failed to fetch query data",
                    type: "error",
                });
                setLoading(false);
                throw new Error('Failed to fetch query data');
            }

            if (!courtsResponse.ok) {
                setPageError({
                    isVisible: true,
                    message: "Failed to fetch count data",
                    type: "error",
                });
                setLoading(false);
                throw new Error('Failed to fetch count data');
            }

            const queryData = await queryResponse.json();
            const courtsData = await courtsResponse.json();

            setResult(queryData);
            setCourts(courtsData);
            setLoading(false);
        } catch (error) {
            setPageError({
                isVisible: true,
                message: "Error fetching data",
                type: "error",
            });
            setLoading(false);
            console.error('Error fetching data:', error.message);
            navigate('/login');
        }
    };

    const handleClearFilter = () => {
        setFilter(filterFieldsData);
    };

    const handleNextPage = () => {
        setPagination(prev => ({
            ...prev,
            offset: prev.offset + prev.limit,
            page: prev.page + 1
        }));
    };

    const handlePrevPage = () => {
        setPagination(prev => ({
            ...prev,
            offset: (prev.offset > 0) ? prev.offset - prev.limit : 0,
            page: prev.page - 1
        }));
    };

    const handleLimitRows = (param) => {
        const newLimit = param;
        setPagination(prev => ({
            ...prev,
            limit: Number(newLimit)
        }));
    };

    return (
        <>
            {pageError.isVisible && <Alert type={pageError.type} message={pageError.message} />}
            <TileContainer>
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex flex-col">
                        <SelectInput
                            label="Wybierz sąd"
                            name="sad"
                            value={filter.sad}
                            onChange={handleInputChange}
                            className="border-b border-slate-300 p-2"
                            options={courts?.data.map((court) => court.court)}
                        />
                    </div>

                    <div className="flex flex-col">
                        <Input
                            label="Numer Księgi"
                            name="numerKsiegi"
                            value={filter.numerKsiegi}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-5 gap-4">
                    <div className="flex flex-col">
                        <Input
                            label="Województwo"
                            name="wojewodztwo"
                            value={filter.wojewodztwo}
                            onChange={handleInputChange} />

                    </div>

                    <div className="flex flex-col">
                        <Input
                            label="Powiat"
                            name="powiat"
                            value={filter.powiat}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="flex flex-col">
                        <Input
                            label="Gmina"
                            name="gmina"
                            value={filter.gmina}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="flex flex-col">
                        <Input
                            label="Miejscowość"
                            name="miejscowosc"
                            value={filter.miejscowosc}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="flex flex-col">
                        <Input
                            label="Ulica"
                            name="ulica"
                            value={filter.ulica}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-4 gap-4">
                    <div className="flex flex-col">
                        <Input
                            label="Identyfikator działki"
                            name="identyfikatorDzialki"
                            value={filter.identyfikatorDzialki}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="flex flex-col">
                        <Input
                            label="Numer działki"
                            name="numerDzialki"
                            value={filter.numerDzialki}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="flex flex-col">
                        <Input
                            label="Obręb ewidencyjny"
                            name="obrebEwidencyjny"
                            value={filter.obrebEwidencyjny}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="flex flex-col">
                        <Input
                            label="Sposób korzystania"
                            name="sposobKorzystania"
                            value={filter.sposobKorzystania}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-5 gap-4">
                    <div className="flex flex-col">
                        <Input
                            label="Numer budynku"
                            name="numerBudynku"
                            value={filter.numerBudynku}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="flex flex-col">
                        <Input
                            label="Numer lokalu"
                            name="numerLokalu"
                            value={filter.numerLokalu}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="flex flex-col">
                        <Input
                            label="Kondygnacja"
                            name="kondygnacja"
                            value={filter.kondygnacja}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="flex flex-col">
                        <Input
                            label="Powierzchnia lokalu od:"
                            name="powierzchniaLokaluOd"
                            value={filter.powierzchniaLokaluOd}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="flex flex-col">
                        <Input
                            label="Powierzchnia lokalu do:"
                            name="powierzchniaLokaluDo"
                            value={filter.powierzchniaLokaluDo}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-5 gap-4">
                    <div className="flex flex-col">
                        <Input
                            label="Imię"
                            name="imieWlasciciela"
                            value={filter.imieWlasciciela}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="flex flex-col">
                        <Input
                            label="Nazwisko"
                            name="nazwiskoWlasciciela"
                            value={filter.nazwiskoWlasciciela}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="flex flex-col">
                        <Input
                            label="Imię ojca"
                            name="imieOjca"
                            value={filter.imieOjca}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="flex flex-col">
                        <Input
                            label="Imie matki"
                            name="imieMatki"
                            value={filter.imieMatki}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="flex flex-col">
                        <Input
                            label="PESEL"
                            name="peselWlasciciela"
                            value={filter.peselWlasciciela}
                            onChange={handleInputChange}
                        />
                    </div>

                </div>

                <div className="grid grid-cols-2 gap-4">
                    <div className="flex flex-col">
                        <SelectInput
                            label="Wybierz sąd"
                            name="kwBook"
                            value={filter.kwBook}
                            onChange={handleInputChange}
                            className="border-b border-slate-300 p-2"
                            options={booksList}
                        />
                    </div>
                    <div className="flex flex-col">
                        <Input
                            label="Przeszukaj zawartość"
                            name="deepSearch"
                            value={filter.deepSearch}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className="grid grid-cols-5 gap-4">
                    <div className="flex flex-col">
                        <Checkbox
                            label="Komornik"
                            name="komornik"
                            value={filter.komornik}
                            checked={filter.komornik}
                            onChange={handleCheckboxChange}
                        />
                    </div>
                    <div className="flex flex-col">
                        <Checkbox
                            label="Darowizny"
                            name="darowizny"
                            value={filter.darowizny}
                            checked={filter.darowizny}
                            onChange={handleCheckboxChange}
                        />
                    </div>
                    <div className="flex flex-col">
                        <Checkbox
                            label="Warte sprawdzenia"
                            name="warteSprawdzenia"
                            value={filter.warteSprawdzenia}
                            checked={filter.warteSprawdzenia}
                            onChange={handleCheckboxChange}
                        />
                    </div>
                    <div className="flex flex-col">
                        <Checkbox
                            label="Umowy deweloperskie"
                            name="umowyDeweloperskie"
                            value={filter.umowyDeweloperskie}
                            checked={filter.umowyDeweloperskie}
                            onChange={handleCheckboxChange}
                        />
                    </div>
                    <div className="flex flex-col">
                        <Checkbox
                            label="Umowy przedwstępne"
                            name="umowyWarunkowePrzedwstepne"
                            value={filter.umowyWarunkowePrzedwstepne}
                            checked={filter.umowyWarunkowePrzedwstepne}
                            onChange={handleCheckboxChange}
                        />
                    </div>
                </div>

                <button className="bg-slate-100 border border-slate-200 p-2" onClick={() => handleSearch(filter)}>Szukaj</button>
                <button className="bg-slate-100 border border-slate-200 p-2" onClick={() => handleClearFilter()}>Wyczyść filtry</button>
            </TileContainer>
            <TileContainer>
                <div className="flex items-end justify-between">
                    <div className="flex flex-col">
                        <span className="text-sm text-slate-700">
                            Strona <span className="font-semibold text-slate-900 ">{pagination.page} z {result?.data?.totalPages}</span>
                        </span>
                        <div className="inline-flex mt-2 xs:mt-0">
                            <button disabled={pagination.offset <= 0} onClick={() => handlePrevPage()} className="disabled:bg-slate-600 disabled:cursor-not-allowed flex items-center justify-center px-3 h-8 text-sm font-medium text-white bg-slate-800 rounded-s hover:bg-slate-900">
                                Prev
                            </button>
                            <button onClick={() => handleNextPage()} className="disabled:bg-slate-600 disabled:cursor-not-allowed flex items-center justify-center px-3 h-8 text-sm font-medium text-white bg-slate-800 border-0 border-s border-slate-700 rounded-e hover:bg-slate-900">
                                Next
                            </button>
                        </div>
                    </div>

                    <div className="flex flex-col">
                        <span className="text-sm text-slate-700">Liczba wierszy</span>
                        <select
                            value={pagination.limit || "25"}
                            onChange={(e) => handleLimitRows(e.target.value)}
                            className="inline-flex mt-2 xs:mt-0 px-3 h-8 text-sm font-medium text-white bg-slate-800 rounded hover:bg-slate-900"
                        >
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                        </select>
                    </div>
                </div>

                {isLoading ? <MaskLoader /> :
                    result && result.status === "success" && result.data.data.length > 0 ? (
                        <div>
                            <div className="overflow-x-auto">
                                <table className="min-w-full bg-white border border-slate-200">
                                    <thead>
                                        <tr className="bg-slate-100 text-slate-600 uppercase text-sm leading-normal">
                                            {/* Render dynamic table headers */}
                                            {Object.keys(displayedColumns).map((column, index) => (
                                                <th
                                                    key={index}
                                                    onClick={() => handleHeaderClick(column)}
                                                    className="py-3 px-6 text-left cursor-pointer"
                                                >
                                                    {displayedColumns[column]}
                                                    {sortState.column === column && (sortState.direction === 'asc' ? ' 🔼' : ' 🔽')}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody className="text-slate-600 text-sm font-light">
                                        {result.data.data.map((item, index) => (
                                            <tr
                                                key={index}
                                                className="border-b border-slate-200 hover:bg-slate-50 cursor-pointer"
                                                onClick={() => navigate('/dashboard/ksiegi/' + item.w_id)}
                                            >
                                                {/* Render table rows */}
                                                {Object.keys(displayedColumns).map((column, index) => (
                                                    <td key={index} className="py-3 px-6">{item[column]}</td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : (
                        <p>Brak wyników</p>
                    )}
            </TileContainer>
        </>
    );
};

export default Kw;
