import React from 'react'

const AlertBox = ({ message }) => {
    return (
        <div className="fixed z-50 right-12 top-12 flex flex-col p-4 bg-white shadow-md hover:shodow-lg border">
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <div className="flex flex-col ml-3">
                        <div className="font-medium leading-none">Info</div>
                        <p className="text-sm text-gray-600 leading-none mt-1">{message}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlertBox